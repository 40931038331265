/* 서비스 화면 */
.section1 {
  &.section1_bg {
    height: 100%;
    background-image: url("https://cdn.pixabay.com/photo/2015/06/20/07/24/color-815554_1280.png");
    background-repeat: repeat;
    background-size: cover;
    background-position: bottom;
  }
}

/* 서비스 화면 */
.section2 {
  &.section2_bg {
    height: 100%;
    background-image: url("https://cdn.pixabay.com/photo/2015/06/20/07/24/color-815554_1280.png");
    background-repeat: repeat;
    background-size: cover;
    background-position: bottom;
  }

  .card-container {
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 25px 0 rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.25);
  }

  .card-container .image-container img {
    width: 100%;
    max-width: 400px;
    height: auto;
    min-height: 300px;
    object-fit: contain;
    border-radius: 8px;
  }

  .card-container .card-header h4 {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 0px;
  }

  .card-container .card-header p {
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
  }

  .card-container .card-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
  }

  .card-container .card-btn a {
    width: auto;
    height: auto;
    padding: 8px;
    text-decoration: none;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.25);
    color: black;
    font-size: 15px;
    font-weight: 600;
  }
}

/* 서비스2 화면 */
.section3 {
  &.section3_bg {
    height: 100%;
    background-image: url("https://cdn.pixabay.com/photo/2017/09/06/11/41/clean-2721104_1280.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
  .historyEnkino > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .historyEnkino > li ul li {
    padding-bottom: 1.5rem;
  }

  .circle {
    border-radius: 50%;
    position: absolute;
    z-index: -1;
  }

  .one {
    background-image: linear-gradient(to bottom right, #fefa82, #facc79);
    width: 15rem;
    height: 15rem;
    left: 60%;
    top: 25%;
  }

  .two {
    background-image: linear-gradient(to bottom right, #f79b71, #eb6d5f);
    width: 8rem;
    height: 8rem;
    right: 60%;
    bottom: 30%;

    @media screen and (max-width: 1200px) {
      right: 65%;
    }

    @media screen and (max-width: 780px) {
      right: 70%;
      bottom: 28%;
    }

    @media screen and (max-width: 600px) {
      right: 75%;
    }
  }

  .glass {
    // max-width: 22rem;
    padding: 2.5em 2em;
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4) !important;
    border: 2px solid rgba(255, 255, 255, 0.5) !important;
    border-radius: 2em;
    color: white;

    // @media screen and (max-width: 600px) {
    //   width: 70vw;
    // }

    // @media screen and (max-width: 560px) {
    //   width: 80vw;
    // }

    // @media screen and (max-width: 450px) {
    //   width: 90vw;
    // }
  }

  .img {
    & img {
      width: 100%;
      border-radius: 50%;
    }
  }

  .info {
    padding-left: 1.2rem;

    @media screen and (max-width: 500px) {
      padding-left: 1rem;
    }

    .name {
      padding: 0.5rem 0 2rem 0;

      & h3 {
        font-size: 1.2rem;
        line-height: 110%;
        font-weight: 400;
      }

      & p {
        font-size: 0.9rem;
        color: #ddd;
      }
    }

    .des {
      color: #fff;

      .r-n {
        color: #ddd;
        padding-right: 1rem;
      }
    }
  }

  .btn {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .far {
      text-align: right;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
    }

    button {
      width: fit-content;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 5px;
      cursor: pointer;

      > a {
        font-size: 1rem;
        font-weight: 900;
        padding: 0.5rem 1rem;
      }

      :hover {
        background-color: rgba(255, 255, 255, 1);
        border-radius: 5px;
        transition: all 0.3s ease;
      }

      @media screen and (max-width: 600px) {
        font-size: 0.7rem;
        padding: 2px 6px;
        font-weight: normal;
      }
    }
  }
}

// 오시는 길

.section4 {
  &.section4_bg {
    height: 100%;
    background-image: url("https://cdn.pixabay.com/photo/2015/06/20/07/24/color-815554_1280.png");
    background-repeat: repeat;
    background-size: cover;
    background-position: bottom;
  }

  .card-container {
    width: 100%;
    max-width: 1200px;
    height: 750px;
    padding: 15px;
    margin-top: 100px;
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 25px 0 rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.25);
  }

  .card-container .image-container img {
    width: 100%;
    max-width: 400px;
    height: auto;
    min-height: 300px;
    object-fit: contain;
    border-radius: 8px;
  }

  .card-container .card-header h4 {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 0px;
  }

  .card-container .card-header p {
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
  }

  .card-container .card-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
  }

  .card-container .card-btn a {
    width: auto;
    height: auto;
    padding: 8px;
    text-decoration: none;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.25);
    color: black;
    font-size: 15px;
    font-weight: 600;
  }
}

.glass_box {
  backdrop-filter: blur(4px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 25px 0 rgba(255, 255, 255, 0.25);
  border: 3px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  word-wrap: break-word;
  padding: 2rem;
}
